@use 'three-dots' with (
  $dot-color: #5CC6D0
);
@import 'assets/styles/variables';



* {
  box-sizing: border-box;
  /* outline: 1px dashed #f00; */
}

body {
  margin: 5em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui-provider {
  background-color: #ffffff !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.ui-provider>svg{
  display: none;
  width: 100%;
}

iframe  {
  display: none;
}