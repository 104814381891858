@import 'assets/styles/variables';

.image-quality-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 20%;
  .image-quality {
    width: 15px;
    height: auto;
    color: transparent;

    &.active {
      color: yellow;
    }
  }
}
