@import 'assets/styles/variables';

.loading-screen-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;
  font-size: $font-size-medium;
  text-align: center;

  background-color: #ffffff;

  .spinner-wrapper {
    position: relative;
    height: 3.5rem;
  }

  .message-wrapper {
    height: 6.5rem;
    color: $secondary;
  }

}