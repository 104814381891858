@import 'assets/styles/variables';

.results-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 98vh;
  color: $secondary;
  font-size: $font-size-medium;
  text-align: center;
  .logo {
    display: flex;
    align-content: center;
    justify-content: center;
    padding:  15px 0; 
    img {
      width: 65%;
    }
  }
  .name {
    color: $secondary;
  }
  .msg-block {
    padding: 0 5%;

    .title {
      font-weight: 700;
      font-size: 18px;
      color: $secondary;

      .name {
        color: $secondary;
      }
    }
    .description {
      font-weight: 400;
      font-size: 14px;
    }
  }

  .results {
    min-height: 9.875rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    justify-content: center;


    .item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      font-weight: $results-item-font-weight;
      font-size: $results-item-font-size;
      color: $results-item-color;
      border-bottom: 1px solid rgba($white, 0.4);

      .title {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        min-width: 12.5rem;

        .icon {
          font-size: $font-size-nano;
          margin-right: 1.3rem;

          img {
            width: 50%;
          }
        }
      }

      .value {
        text-align: right;
        color: $secondary;
        .sign {
          margin-left: 0.25rem;
        }
      }
    }

    .notification {
      display: flex;
      flex-direction: row;
      color: $results-item-color;
      text-align: left;
      font-size: $results-item-font-size;

      img {
        margin-right: 0.5rem;
      }
    }

  }

  .input-email {
    width: 20rem;
    box-sizing: border-box;
    margin: 0.5rem;
    padding: 1.3rem 1.625rem;
    border-radius: 10px;
    border: none;
  }


  .button-container {
    width: 100%;
    display: flex;
    justify-content: center;

    margin-top: 1rem;
    .ui-button {
      background-color: #4EC0CA;
      width: 100%;
      padding: 1.5rem;
    }
  }
}
