@import 'assets/styles/variables';

.info-container {
  margin-bottom: 1rem;

  .info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%; 
    .data {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 25px;
      margin-top: 1rem; 
      .item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-weight: $info-item-font-weight;
        color: $info-item-color;
        height: 60px;
        .item-img {
          width: 100%; // todo check
          height: 100%;
          max-width: 35px;
          max-height: 35px;
        }

        .item-img-mark {
          width: 100%; // todo check
          height: 100%;
          max-width: 20px;
          max-height: 20px;
        }

        .name {
          font-size: $font-size-nano;
          font-weight: $info-item-font-weight;
          color: $secondary;
        }

        .loading {
          .dot-flashing {
           
          }
        }

       
      }

    }
  }
}


@keyframes animateC {
  0% {
    transform:rotate(0deg);
  }
  100% {
    transform:rotate(360deg);
  }
}

@keyframes animate {
  0% {
    transform:rotate(45deg);
  }
  100% {
    transform:rotate(405deg);
  }
}