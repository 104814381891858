@import 'assets/styles/variables';

.bad-conditions-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 98vh;
  color: $white;
  font-size: $font-size-medium;
  text-align: center;
  max-width: 33.5rem;
  margin: 0 auto;

  .logo {
    display: flex;
    align-content: center;
    justify-content: center;
    padding:  15px 0; 
    img {
      width: 65%;
    }
  }

  .title-error {
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    margin-bottom: 1.75rem;

    &:before {
      background-image: url('/assets/images/error-icon.svg');
      content: '';
      width: 1rem;
      height: 1rem;
      display: inline-block;
      background-position: 50%;
      background-repeat: no-repeat;
      margin-right: 0.25rem;
    }
  }

  .message-container {
    margin-bottom: 2.85rem;
    padding: 0 5%;
    .title {
      font-size: 1.25rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
      color: $secondary;
    }
  
    .content {
      font-size: 0.875rem;
      color: $secondary;
    }
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: center;

    margin-top: 1rem;
    .ui-button {
      background-color: #4EC0CA;
      width: 100%;
      padding: 1.5rem;
    }
  }



}
