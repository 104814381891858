.measurement-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 98vh;
  position: relative;

  .measurement-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .video-container {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      /* Ajuste o valor máximo conforme necessário */
      position: relative;

      &::before {
        content: "";
        background: #4EC0CA;
        opacity: 0.25;
        filter: blur(100px);
        width: 100%;
        padding-top: 90%;
        /* Proporção quadrada */
        border-radius: 50%;
        position: absolute;
      }

      &::after {
        content: "";
        border: 8px solid #4EC0CA;
        width: 100%;
        /* Ajuste conforme necessário */
        padding-top: 95%;
        /* Proporção quadrada */
        border-radius: 50%;
        position: absolute;
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.75);
      }

      .video-wrapper {
        width: 240px;
        /* Largura fixa */
        height: 240px;
        /* Altura fixa */
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        overflow: hidden;
        border: 4px solid #1C2F4B;

        .video,
        .canvas {
          height: 100%;
          width: auto;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          position: absolute;

          &.invert {
            transform: translate(-50%, -50%) scaleX(-1);
          }

          &.vertical {
            height: auto;
            width: 100%;
          }
        }

        .canvas {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%) scaleX(-1);
          z-index: 10;
          height: 100%;
        }
      }
    }
  }

  .start-block-wrapper {
    .text-message {
      color: #1C2F4B;
    }

    .message-container{
      padding: 0 5%;
    }
  }

  .button-start {
    width: 100%;
    display: flex;
    justify-content: center;

    margin-top: 1rem;
    .ui-button {
      background-color: #4EC0CA;
      width: 100%;
      padding: 1.5rem;
    }
  }

  .video-content{
    max-width: 3000px;
    max-height: 300px;
  }

  .logo {
    display: flex;
    align-content: center;
    justify-content: center;
    padding:  15px 0; 
    img {
      width: 65%;
    }
  }

  .imageQuality-container{
    margin: 1rem;
    display: flex;
    justify-content: center;
  }

  .info-content{
    height: 100px; 
  }
}