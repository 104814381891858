@import 'assets/styles/variables';

.text-message-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 4.5rem;

  .text-message {
    color: $white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.in {
      overflow: hidden;
      transition: max-height 0.25s ease-out, opacity 0.25s ease-out;
      opacity: 1;
    }
    
    &.out {
      overflow: hidden;
      transition: max-height 0.25s ease-out, opacity 0.25s ease-out;
      opacity: 0;
    }
    
    .welcome-title {
      font-weight: $font-weight-bold;
      font-size: 0.8rem;
      margin: 0;
    } 
    .welcome-description {
      font-weight: $font-weight-normal;
      font-size: 0.775rem;
      margin: 0;
    } 
  }
}
